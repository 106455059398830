<template>
  <div>
    <div class="center">
      <div class="center-top"></div>
      <div class="top">
        <i class="el-icon-house"></i>
        <span>管理员管理</span>
      </div>
      <div class="main">
        <el-button class="btn" size="mini" type="primary" @click="handleAdd"
          >添加</el-button>
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#409eff', color: '#000' }"
        >
          <el-table-column label="ID" width="150">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.id }}</span>
            </template>
          </el-table-column>
          <el-table-column label="昵称" width="150">
            <template slot-scope="scope">
              <p>{{ scope.row.username }}</p>
            </template>
          </el-table-column>
          <el-table-column label="密码盐" width="150">
            <template slot-scope="scope">
              <p>{{ scope.row.salt }}</p>
            </template>
          </el-table-column>
          <el-table-column label="所属用户组名称" width="150">
            <template slot-scope="scope">
              <p>{{ scope.row.name }}</p>
            </template>
          </el-table-column>
          <el-table-column label="姓名" width="150">
            <template slot-scope="scope">
              <p>{{ scope.row.realname }}</p>
            </template>
          </el-table-column>
          <el-table-column label="电话" width="150">
            <template slot-scope="scope">
              <p>{{ scope.row.phone }}</p>
            </template>
          </el-table-column>
          <el-table-column label="身份证号码" width="150">
            <template slot-scope="scope">
              <p>{{ scope.row.number }}</p>
            </template>
          </el-table-column>
          <el-table-column label="token" width="150">
            <template slot-scope="scope">
              <p>{{ scope.row.token }}</p>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleEdit(scope.$index,scope.row,scope.row.id,scope.row.username,scope.row.salt,scope.row.name,scope.row.realname,scope.row.phone,scope.row.number,scope.row.token)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index,scope.row,scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="bottom">
          <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="arr"
                :page-size="pagesize"
                layout="total, sizes, prev, pager, next"
                :total="total">
              </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="show" class="dialog" width="45%" title="添加管理员信息">
      <div class="modal-box">
        <ul>
          <li>昵称：<input type="text" v-model="username"/></li>
          <li>密码：<input type="password" v-model="password"/></li>
          <li>密码盐：<input type="text" v-model="salt"/></li>
          <li>所属用户组名称：<input type="text" v-model="name"/></li>
          <li>姓名：<input type="text" v-model="realname"/></li>
          <li>电话：<input type="text" v-model="phone"/></li>
          <li>身份证号码：<input type="text" v-model="number"/></li>
          <li>token：<input type="text" v-model="token"/></li>
        </ul>
        <div class="modal-box-btn">
          <button @click="add" type="primary">确定</button>
          <button @click="empty">重置</button>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="hide" class="dialog" width="45%" title="修改管理员信息">
      <div class="modal-box">
        <ul>
          <li>昵称：<input type="text" v-model="username"/></li>
          <li>密码：<input type="password" v-model="password"/></li>
          <li>密码盐：<input type="text" v-model="salt"/></li>
          <li>所属用户组名称：<input type="text" v-model="name"/></li>
          <li>姓名：<input type="text" v-model="realname"/></li>
          <li>电话：<input type="text" v-model="phone"/></li>
          <li>身份证号码：<input type="text" v-model="number"/></li>
          <li>token：<input type="text" v-model="token"/></li>
        </ul>
        <div class="modal-box-btn">
          <button @click="update" type="primary">修改</button>
          <button @click="empty">重置</button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
    
<script>
import { getAdmin,Adminadd,Adminedit,Admindel } from "@/api/manage";
export default {
  name: "AdminStrator",
  data() {
    return {
      Name: "",
      total:0,  //总数据条数
      currentPage: 1,  //当前所在页默认是第一页
      pagesize:5,  //每页显示多少行数据 默认设置为5
      ticket:[],  //这里是从后端获取的所有数据
      arr:[5,10],
      tableData: [],
      show: false,
      hide: false,
      id:'',
      username:'',
      password:'',
      salt:'',
      name:'',
      realname:'',
      phone:'',
      number:'',
      token:'',
      session_key:''
    };
  },
  async created() {
    this.session_key = JSON.parse(sessionStorage.getItem("session_key"));
    this.getAdmin();
  },
  methods: {
    getAdmin() {
      getAdmin().then((res) => {
        console.log(res)
        if(res.code===200){
          this.ticket = res.data.infor
          // this.tableData = res.data.infor
            // //获取返回记录的总行数
            this.total = res.data.count
            // //获取当前页的数据
            this.getPageInfo()
        }else{
          console.log('失败')
        }
      })
    },
    // 获取当前页的数据信息
    getPageInfo (){
        //清空pageTicket中的数据
        this.tableData = []
        // 获取当前页的数据
        for(var i=(this.currentPage-1) * this.pagesize;i<this.total;i++){
          //把遍历的数据添加到pageTicket里面
          this.tableData.push(this.ticket[i]);
          //判断是否达到一页的要求
          if(this.tableData.length===this.pagesize) break;
        }
      },
    handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.pagesize = val
        // this.getPageInfo()
      },
      handleCurrentChange (val) {
      console.log(`${val} `);
        this.currentPage = val
        // this.getPageInfo()
    },
    handleAdd() {
      this.show = true;
    },
    handleEdit(index,row,id,username,salt,name,realname,phone,number,token) {
      console.log(index,row,id);
      this.id = id;
      this.username = username;
      this.salt= salt;
      this.name = name;
      this.realname= realname;
      this.phone = phone;
      this.number = number;
      this.token= token;
      this.hide = true;
    },
    async handleDelete(index,row, id) {
        console.log(index,row, id);
        const res = await Admindel(id,this.session_key)
        console.log(res)
      },

    async add() {
      this.Name = "";
      this.show = false;
      const res = await Adminadd(
        this.username,
        this.password,
        this.salt,
        this.name,
        this.realname,
        this.phone,
        this.number,
        this.token,
        this.session_key);
        console.log(res)
    },
    async update() {
      this.hide = false;
      const res = await Adminedit(
        this.id,
        this.username,
        this.password,
        this.salt,
        this.name,
        this.realname,
        this.phone,
        this.number,
        this.token,
        this.session_key);
        console.log(res)
    },
    empty() {
      this.Name = "";
    },
  },
};
</script>
    
<style scoped>
li {
  list-style: none;
}
.center {
  width: auto;
  height: 100vh;
  margin: 60px 0 0 200px;
  background-color: #f2f6fc;
}
.center-top {
  width: 100%;
  height: 50px;
  background-color: #fff;
}
.top {
  margin: 20px 0 20px 30px;
}
.top i {
  padding-left: 5px;
  border-left: 2px solid #409eff;
}
.top span {
  margin-left: 10px;
  padding-left: 5px;
  border-left: 2px solid #ccc;
  font-size: 15px;
}
.main {
  margin: 20px 50px;
}
.btn {
  margin: 0 0 15px;
}
.bottom {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background-color: #fff;
  padding: 30px 0 20px;
}
.modal-box {
  position: relative;
  height: 550px;
  text-align: center;
  font-size: 18px;
}
.modal-box ul {
  margin-right: 200px;
  text-align: right;
}
.modal-box li {
  margin-bottom: 15px;
}
.modal-box input {
  width: 60%;
  height: 40px;
}
.modal-box-btn {
  position: absolute;
  bottom: 0;
  right: 30px;
}
.modal-box-btn button {
  width: 80px;
  height: 30px;
  margin-right: 20px;
}
</style>
  
  