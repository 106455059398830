<template>
    <div>
      <TimeHead></TimeHead>
      <div class="left">
        <ul>
          <li><router-link to="/device/management">设备管理</router-link></li>
        </ul>
      </div>
      <router-view></router-view>
    </div>
  </template>
  
  <script>
  export default {
    name: "SheBei",
    data() {
      return {};
    },
    methods: {},
  };
  </script>
  
  <style  scoped>
  .left {
    position: fixed;
    top: 0;
    left: 0;
    width: 200px;
    height: 100%;
    background-color: #e1e6f0;
  }
  .left ul {
    margin-top: 130px;
    text-align: center;
  }
  .left a {
    display: block;
    width: 100%;
    height: 60px;
    line-height: 60px;
    color: #000;
  }
  .left a.router-link-active {
    color: #3370ff;
    background-color: rgba(0, 0, 0, 0.1);
  }
  </style>