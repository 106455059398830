<template>
  <div class="content">
    <div>
      <img src="@/assets/images/logo.png" alt="" />
    </div>
    <div class="head">
      <ul>
        <li>
          <router-link to="/home">首页</router-link>
        </li>
        <li><router-link to="/user">用户管理</router-link></li>
        <li><router-link to="/shopping">商品管理</router-link></li>
        <li><router-link to="/manage">管理员管理</router-link></li>
        <li><router-link to="/usergroup">用户组</router-link></li>
        <li><router-link to="/device">设备管理</router-link></li>
      </ul>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // listArr:[
      //       {id:1,title:'首页'},
      //       {id:2,title:'短剧管理'},
      //       {id:3,title:'审核管理'},
      //       {id:4,title:'会员管理'},
      //       {id:5,title:'财务管理'},
      //     ],
      //     listIndex:0
      // activeIndex: "1",
    };
  },
  methods: {
    list(index){
          console.log(index)

          this.listIndex = index;

          if(index==0){
            window.location.href ='#/home'
          }
          else if(index==1){
            window.location.href ='#/user/basic'
          }
          else if(index==2){
            window.location.href ='#/shopping/equiqment'
          }
          else if(index==3){
            window.location.href ='#/manage/adminstrator'
          }
          else if(index==4){
            window.location.href ='#/usergroup/group'
          }
          else if(index==5){
            window.location.href ='#/device/management'
          }
        }
    // handleSelect(key, keyPath) {
    //   console.log(key, keyPath);
    // },
  },
};
</script>

<style scope>
*{
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
li{
  list-style: none;
}
.content {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #545c64;
  overflow: hidden;
  z-index: 999;
}
.content div {
  width: 200px;
  text-align: center;
}
.content img {
  width: 70px;
  height: 70px;
}
.head a{
 display: block;
  height: 60px;
  width: 120px;
  color: #fff;
}
.head a.router-link-active{
  background-color: #3370ff;
}
.head ul{
  display: flex;
  flex-direction: row;
  width: 500px;
}
</style>