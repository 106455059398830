import Vue from "vue"
import Vuex from 'vuex'
import user from "./modules/user"
Vue.use(Vuex)
const store = new Vuex.Store({
    state:{},
    getters:{
        // token(state){
        //     return state.user.userInfo.token
        // }
    },
    mutations:{},
    actions:{},
    modules: {
        user
    }
})
export default store