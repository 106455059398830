<template>
  <div class="box">
    <div class="main">
      <div class="min-top">
        <div class="min-top-img">
          <img src="@/assets/images/logo.png" alt="" />
        </div>
        <h3>欢迎登录</h3>
        <h4>
          时光梭管理平台
          <span>/管理端</span>
        </h4>
      </div>
      <div class="from">
        <div class="inp">
          <input
            v-model="username"
            type="text"
            placeholder="请输入用户名"
          /><br />
          <input
            v-model="password"
            type="password"
            placeholder="请输入密码"
          />
        </div>
        <div class="pass">
          <p>
            <input type="checkbox" />
            <span>记住密码</span>
          </p>
          <a href="#">忘记密码？</a>
        </div>
        <el-button class="btn" type="primary" @click="submit">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from 'md5';
import { postLogin } from "@/api/manage";
export default {
  name: "MeLogin",
  data() {
    return {
      username: "",
      password: "",
      session_key:""
    };
  },
  created() {
  },
  methods: {
    validFn() {
      if (this.username == "") {
        this.$message.error("请输入用户名");
        return false;
      }else
      if (this.password == "") {
        this.$message.error("请输入正确的密码");
        return false;
      }else{
        return true;
      }
      
    },
    async submit() {
      if (!this.validFn()) {
        return;
      }

      const res = await postLogin(this.username, md5(this.password));
      console.log(res);
      
      if(res.code == 200) {
        console.log(res.data.token);
        this.session_key = res.data.token
        sessionStorage.setItem('session_key',JSON.stringify(this.session_key))
        // sessionStorage.setItem('session_key',1)
        this.$router.push('/home')
      }else{
        console.log("操作失败");
      }

      // this.$store.commit('user/setToken', res.data)
       
      
    },
  },
};
</script>

<style scoped>
.box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url(@/assets/images/bgbig.jpg) no-repeat center center;
  background-attachment: fixed;
  background-size: 100% 100%;
}
.main {
  padding: 20px 100px;
  background: #fff;
}

.min-top {
  margin-bottom: 15px;
}

.min-top-img {
  text-align: center;
}
.min-top-img img{
  width: 120px;
  height: 120px;
}

.min-top h3 {
  margin: 0;
  color: #646463;
  font-size: 41px;
  font-weight: 500;
}

.min-top h4 {
  margin: 0;
  color: #272c2c;
  font-size: 23px;
  font-weight: 500;
}
.min-top h4 span {
  font-size: 16px;
}
.inp input {
  width: 350px;
  height: 40px;
  margin-top: 20px;
  padding-left: 20px;
  border: 1px solid #ccc;
}
.pass {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0;
  font-size: 14px;
}
.btn {
  width: 370px;
  height: 40px;
  background-color: #409eff;
  margin: 30px 0;
}
</style>