<template>
  <div class="left">
      <el-row class="tac">
        <el-col :span="3">
          <el-menu
            default-active="1"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
            background-color="#e1e6f0"
            text-color="#000"
          >
            <a href="#/home">
              <el-menu-item index="1">
                <i class="el-icon-house"></i>
                <span slot="title">首页</span>
              </el-menu-item>
            </a>
            <el-submenu index="2">
              <template slot="title">
                <i class="el-icon-monitor"></i>
                <span>短剧管理</span>
              </template>
              <el-menu-item-group>
                <a href="#/playletclassify"
                  ><el-menu-item index="2-1">短剧分类</el-menu-item></a
                >
                <a href="#/playletlist"
                  ><el-menu-item index="2-2">短剧列表</el-menu-item></a
                >
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">
                <i class="el-icon-s-operation"></i>
                <span>审核管理</span>
              </template>
              <el-menu-item-group>
                <a href="#/staffgp"><el-menu-item index="3-1">员工分组</el-menu-item></a>
                <a href="#/auditor"><el-menu-item index="3-2">审核人列表</el-menu-item></a>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="4">
              <template slot="title">
                <i class="el-icon-data-line"></i>
                <span>会员管理</span>
              </template>
              <el-menu-item-group>
                <a href="#/viplist"><el-menu-item index="4-1">会员列表</el-menu-item></a>
                <a href="#/viptype"><el-menu-item index="4-2">会员类型</el-menu-item></a>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="5">
              <template slot="title">
                <i class="el-icon-office-building"></i>
                <span>财务管理</span>
              </template>
              <el-menu-item-group>
                <a href="#/finance"><el-menu-item index="5-1">财务报表</el-menu-item></a>
                <a href="#/turnover"><el-menu-item index="5-2">日/月营业额</el-menu-item></a>
                <a href="#/sales"><el-menu-item index="5-3">销售报表</el-menu-item></a>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </el-col>
      </el-row>
    </div>
</template>

<script>
export default {
    data() {
    return {
      activeNames: ["1"],
    };
  },
  methods: {
    // 点击切换
    clickNav(index) {
      this.navIndex = index;
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
      console.log("haha");
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
      console.log("mam");
    },
  },
}
</script>

<style  scoped>
.center {
  position: relative;
  width: auto;
  height: 100vh;
  margin: 60px 0 0 200px;
  background-color: #f2f6fc;
  z-index: -999;
}
.center-top {
  width: 100%;
  height: 50px;
  background-color: #fff;
}
.nav-li span {
  display: block;
  margin-top: 20px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.left {
  position: fixed;
  top: 60px;
  left: 8px;
}
.left ul {
  width: 200px;
  height: 100vh;
  background-color: #e1e6f0;
}
.navindex {
  padding: 15px;
  text-align: center;
}
.navindex.active {
  color: #3370ff;
  background-color: #cfd7e7;
}
</style>