import request from '@/utils/request';

// 登录
export const getLogin = () => {
  return request.get('/login')
} 

export const postLogin = (username, password) => {
  return request.post('login.index', {
      username:username,
      password:password
  })
} 

// 首页
export const getIndex = () => {
  return request.get('/index')
} 

// 用户管理
// 用户基础信息
export const getUser = () => {
  return request.post('/user.index')  
} 
export const UserAdd = (username,password,phone,address,IC_number,IC_name,balance,oneid,payword,level,session_key) => {
  return request.post('/user.index/add', {
          username: username,
					password: password,
          phone: phone,
					address: address,
          IC_number: IC_number,
          IC_name: IC_name,
          balance: balance,
          oneid: oneid,
          payword: payword,
          level: level,
          session_key: session_key
  })
} 
export const Useredit = (id,username,password,phone,address,IC_number,IC_name,balance,oneid,payword,level,session_key) => {
  return request.post('/user.index/edit', {
    id:id,
    username: username,
    password: password,
    phone: phone,
    address: address,
    IC_number: IC_number,
    IC_name: IC_name,
    balance: balance,
    oneid: oneid,
    payword: payword,
    level: level,
    session_key: session_key
})
} 
export const Userdel = (id,session_key) => {
  return request.post('/user.index/del',{
    id:id,
    session_key:session_key
  })
} 

// 银行卡信息
export const getIcard = () => {
  return request.post('/user.icard')
} 
export const Icardadd = (username,address,name,number,realname,phone,session_key) => {
  return request.post('/user.icard/add',{
    username:username,
    address:address,
    name:name,
    number:number,
    realname:realname,
    phone:phone,
    session_key:session_key
  })
} 
export const Icardedit = (id,username,address,name,number,realname,phone,session_key) => {
  return request.post('/user.icard/edit',{
    id:id,
    username:username,
    address:address,
    name:name,
    number:number,
    realname:realname,
    phone:phone,
    session_key:session_key
  })
} 
export const Icarddel = (id,session_key) => {
  return request.post('/user.icard/del',{
    id:id,
    session_key:session_key
  })
} 

// 收货地址
export const getUseraddress = () => {
  return request.post('/user.address')
}
export const Useraddressadd = (username,name,phone,Shipping_address,Detailed_address,defult,session_key) => {
  return request.post('/user.address/add',{
    username:username,
    name:name,
    phone:phone,
    Shipping_address:Shipping_address,
    realnamDetailed_addresse:Detailed_address,
    defult:defult,
    session_key:session_key
  })
}
export const Useraddressedit = (id,username,name,phone,Shipping_address,Detailed_address,defult,session_key) => {
  return request.post('/user.address/edit',{
    id:id,
    username:username,
    name:name,
    phone:phone,
    Shipping_address:Shipping_address,
    realnamDetailed_addresse:Detailed_address,
    defult:defult,
    session_key:session_key
  })
}
export const Useraddressdel = (id,session_key) => {
  return request.post('/user.address/del',{
    id:id,
    session_key:session_key
  })
}

// 账单列表
export const getUserrecord = () => {
  return request.post('/user.record')
}
export const Userrecordadd = (username,status,pay,txt,time,session_key) => {
  return request.post('/user.record/add',{
    username:username,
    status:status,
    pay:pay,
    txt:txt,
    time:time,
    session_key:session_key
  })
}
export const Userrecordedit = (id,username,status,pay,txt,time,session_key) => {
  return request.post('/user.record/edit',{
    id:id,
    username:username,
    status:status,
    pay:pay,
    txt:txt,
    time:time,
    session_key:session_key
  })
}
export const Userrecorddel = (id,session_key) => {
  return request.post('/user.record/del',{
    id:id,
    session_key:session_key
  })
}


// 商品管理
// 设备
export const getGoods = () => {
  return request.post('/goods.goods')
}
export const Goodsadd = (number,name,tag,status,details,price,remark,url,session_key) => {
  return request.post('/goods.goods/add',{
    number:number,
    name:name,
    tag:tag,
    status:status,
    details:details,
    price:price,
    remark:remark,
    uel:url,
    session_key:session_key
  })
}
export const Goodsedit = (id,number,name,tag,status,details,price,remark,url,session_key) => {
  return request.post('/goods.goods/edit',{
    id:id,
    number:number,
    name:name,
    tag:tag,
    status:status,
    details:details,
    price:price,
    remark:remark,
    uel:url,
    session_key:session_key
  })
}
export const Goodsdel = (id,session_key) => {
  return request.post('/goods.goods/del',{
    id:id,
    session_key:session_key
  })
}
// 上传图片
export const uploadurl = (number,url,session_key) => {
  return request.post('/equipment.index/uploadurl',{
    number:number,
    url:url,
    session_key:session_key
  })
}

// 订单
export const getGoodsorder = () => {
  return request.post('/goods.goodsOrder')
}
export const Goodsorderadd = (number,status,username,equipment_number,quantity,price,time,update_time,remark,transport,add_idd,session_key) => {
  return request.post('/goods.goodsOrder/add',{
    number:number,
    status:status,
    username:username,
    equipment_number:equipment_number,
    quantity:quantity,
    price:price,
    time:time,
    update_time:update_time,
    remark:remark,
    transport:transport,
    add_idd:add_idd,
    session_key:session_key
  })
}
export const Goodsorderedit = (id,number,status,username,equipment_number,quantity,price,time,update_time,remark,transport,add_idd,session_key) => {
  return request.post('/goods.goodsOrder/edit',{
    id:id,
    number:number,
    status:status,
    username:username,
    equipment_number:equipment_number,
    quantity:quantity,
    price:price,
    time:time,
    update_time:update_time,
    remark:remark,
    transport:transport,
    add_idd:add_idd,
    session_key:session_key
  })
}
export const Goodsorderdel = (id,session_key) => {
  return request.post('/goods.goodsOrder/del',{
    id:id,
    session_key:session_key
  })
}

// 管理员管理
// 管理员
export const getAdmin = () => {
  return request.post('/admin.index')
}
export const Adminadd = (username,password,salt,name,realname,phone,number,token,session_key) => {
  return request.post('/admin.index/add',{
    username:username,
    password:password,
    salt:salt,
    name:name,
    realname:realname,
    phone:phone,
    number:number,
    token:token,
    session_key:session_key,
  })
}
export const Adminedit = (id,username,password,salt,name,realname,phone,number,token,session_key) => {
  return request.post('/admin.index/edit',{
    id:id,
    username:username,
    password:password,
    salt:salt,
    name:name,
    realname:realname,
    phone:phone,
    number:number,
    token:token,
    session_key:session_key,
  })
}
export const Admindel = (id,session_key) => {
  return request.post('/admin.index/del',{
    id:id,
    session_key:session_key
  })
}

// 用户组
export const getGroup = () => {
  return request.post('/user.group')
}
export const Groupadd = (name,level,session_key) => {
  return request.post('/user.group/add',{
    name:name,
    level:level,
    session_key:session_key
  })
}
export const Groupedit = (id,name,level,session_key) => {
  return request.post('/user.group/edit',{
    id:id,
    name:name,
    level:level,
    session_key:session_key
  })
}
export const Groupdel = (id,session_key) => {
  return request.post('/user.group/del',{
    id:id,
    session_key:session_key
  })
}

// 设备管理
export const equipment = () => {
  return request.post('/equipment.index')
}
export const equipmentadd = (userid,mac,username,status,remark,session_key) => {
  return request.post('/equipment.index/add',{
    userid:userid,
    mac:mac,
    username:username,
    status:status,
    remark:remark,
    session_key:session_key
  })
}
export const equipmentedit = (id,userid,mac,username,status,remark,session_key) => {
  return request.post('/equipment.index/edit',{
    id:id,
    userid:userid,
    mac:mac,
    username:username,
    status:status,
    remark:remark,
    session_key:session_key
  })
}
export const equipmentdel = (id,session_key) => {
  return request.post('/equipment.index/del',{
    id:id,
    session_key:session_key
  })
}

export const findOutMore = (id,session_key) => {
  return request.post('goods.goodsOrder/findOutMore',{
    id:id,
    token:session_key
  })
}

export const shipments = (number,type,hwb,session_key) => {
  return request.post('goods.goodsOrder/shipments',{
    number:number,
    type:type,
    hwb:hwb,
    token:session_key
  })
}



