<template>
  <div>
    <div class="center">
      <div class="center-top"></div>
      <div class="top">
        <i class="el-icon-house"></i>
        <span>商品管理</span>
        <span>设备</span>
      </div>
      <div class="main">
        <el-button class="btn" size="mini" type="primary" @click="handleAdd">添加设备</el-button>
        <el-table :data="tableData" style="width: 100%" :header-cell-style="{ background: '#409eff', color: '#000' }">
          <el-table-column label="ID" width="100">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.id }}</span>
            </template>
          </el-table-column>
          <el-table-column label="设备编码" width="120">
            <template slot-scope="scope">
              <p>{{ scope.row.number }}</p>
            </template>
          </el-table-column>
          <el-table-column label="设备名字" width="150">
            <template slot-scope="scope">
              <p>{{ scope.row.name }}</p>
            </template>
          </el-table-column>
          <el-table-column label="标签" width="120">
            <template slot-scope="scope">
              <p>{{ scope.row.tag }}</p>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="120">
            <template slot-scope="scope">
              <p>{{ scope.row.status == 0 ? '正常' : '已售完' }}</p>
            </template>
          </el-table-column>
          <el-table-column label="设备详情" width="150">
            <template slot-scope="scope">
              <p>{{ scope.row.details }}</p>
            </template>
          </el-table-column>
          <eqill-editor></eqill-editor>
          <el-table-column label="设备单价" width="160">
            <template slot-scope="scope">
              <p>{{ scope.row.price }}</p>
            </template>
          </el-table-column>
          <el-table-column label="备注" width="160">
            <template slot-scope="scope">
              <p>{{ scope.row.remark }}</p>
            </template>
          </el-table-column>
          <el-table-column label="图片路径" width="160">
            <template slot-scope="scope">
              <el-image style="width: 80px; height: 80px" :src="scope.row.url"></el-image>
              <!-- <el-upload
                action=""
                :auto-upload="false"
                :limit="1"
                list-type="picture-card"
                v-model="url"
              >
              <el-image
                style="width: 80px; height: 80px"
                :src="scope.row.url"
              ></el-image>
                <i class="el-icon-plus"></i>
              </el-upload> -->
            </template>
          </el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleEdit(scope.$index, scope.row, scope.row.id, scope.row.number, scope.row.name,
                scope.row.tag, scope.row.status, scope.row.details, scope.row.price,
                scope.row.remark, scope.row.url)">编辑</el-button>
              <el-button size="mini" type="danger"
                @click="handleDelete(scope.$index, scope.row, scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="bottom">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="currentPage" :page-sizes="arr" :page-size="pagesize" layout="total, sizes, prev, pager, next"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="show" class="dialog" width="45%" title="添加设备">
      <div class="modal-box">
        <ul>
          <li>设备编码：<input type="text" v-model="number" /></li>
          <li>设备名字：<input type="text" v-model="name" /></li>
          <li>设备标签：<input type="text" v-model="tag" /></li>
          <li>设备状态：<input type="text" v-model="status" /></li>
          <li>设备详情：<input type="text" v-model="details" /></li>
          <li>设备单价：<input type="text" v-model="price" /></li>
          <li>备注：<input type="text" v-model="remark" /></li>
          <!-- <li class="modal-box-img">
            <p>图片路径：</p>
            <div>
              <el-upload action="" :auto-upload="false" :limit="1" list-type="picture-card" v-model="url">

                <i class="el-icon-plus"></i>
              </el-upload>

            </div>
          </li> -->

          <!-- <li class="detail"><p>设备详情：</p>

            <quill-editor class='editor' v-model="content" ref="myQuillEditor" :options="editorOption"
              @blur="onEditorBlur($event)" @focus="onEditorFocus($event)" @change="onEditorChange($event)"
              @ready="onEditorReady($event)">
            </quill-editor>

          </li> -->
        </ul>
        <div class="modal-box-btn">
          <button @click="add" type="primary">确定</button>
          <button @click="empty">重置</button>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="hide" class="dialog" width="50%" title="修改设备">
      <div class="modal-box">
        <ul>
          <li>设备编码：<input type="text" v-model="number" /></li>
          <li>设备名字：<input type="text" v-model="name" /></li>
          <li>设备标签：<input type="text" v-model="tag" /></li>
          <li>设备状态：<input type="text" v-model="status" /></li>
          <li>设备详情：<input type="text" v-model="details" /></li>
          <li>设备单价：<input type="text" v-model="price" /></li>
          <li>备注：<input type="text" v-model="remark" /></li>
          <li class="modal-box-img">
            <p>图片路径：</p>
            <div>
              <el-upload action="" :auto-upload="false" :limit="1" list-type="picture-card" v-model="url">
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
          </li>

          <!-- <li>设备详情：
            <quill-editor class='editor' v-model="content" ref="myQuillEditor" :options="editorOption"
              @blur="onEditorBlur($event)" @focus="onEditorFocus($event)" @change="onEditorChange($event)"
              @ready="onEditorReady($event)">
            </quill-editor>

          </li> -->
        </ul>
        <div class="modal-box-btn">
          <button @click="update" type="primary">修改</button>
          <button @click="empty">重置</button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
    
<script>
import { getGoods, Goodsadd, Goodsedit, Goodsdel, uploadurl } from '@/api/manage'

export default {

  name: "EquiqMent",
  data() {
    return {
      Name: '',
      total: 0,  //总数据条数
      currentPage: 1,  //当前所在页默认是第一页
      pagesize: 5,  //每页显示多少行数据 默认设置为5
      ticket: [],  //这里是从后端获取的所有数据
      arr: [5, 10],
      tableData: [],
      show: false,
      hide: false,
      id: '',
      number: '',
      name: '',
      tag: '',
      details: '',
      status: '',
      price: '',
      remark: '',
      url: '',
      session_key: '',
      content:'',
    };
  },
  async created() {
    this.session_key = JSON.parse(sessionStorage.getItem("session_key"));
    this.getGoods();
  },
  methods: {
    async uploadurl() {
      console.log("aha")
      const res = await uploadurl(this.number, this.url, this.session_key)
      console.log(res)
    },
    getGoods() {
      getGoods().then((res) => {
        console.log(res)
        if (res.code === 200) {
          this.ticket = res.data.infor
          // //获取返回记录的总行数
          this.total = res.data.count
          // //获取当前页的数据
          this.getPageInfo()
        } else {
          console.log('失败')
        }
      })
    },
    // 获取当前页的数据信息
    getPageInfo() {
      //清空pageTicket中的数据
      this.tableData = []
      // 获取当前页的数据
      for (let i = (this.currentPage - 1) * this.pagesize; i < this.total; i++) {
        //把遍历的数据添加到pageTicket里面
        this.tableData.push(this.ticket[i]);
        //判断是否达到一页的要求
        if (this.tableData.length === this.pagesize) break;
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagesize = val
      this.getPageInfo()
    },
    handleCurrentChange(val) {
      console.log(`${val} `);
      this.currentPage = val
      this.getPageInfo()
    },
    handleAdd() {
      this.show = true;
    },
    handleEdit(index, row, id, number, name, tag, status, details, price, remark, url) {
      console.log(index, row);
      this.id = id;
      this.number = number;
      this.name = name;
      this.tag = tag;
      this.status = status;
      this.details = details;
      this.price = price;
      this.remark = remark;
      this.url = url;

      this.hide = true;
    },
    async handleDelete(index, row, id) {
      console.log(index, row, id);
      const res = await Goodsdel(id, this.session_key)
      console.log(res)
    },

    async add() {
      this.Name = "";
      this.show = false;
      const res = await Goodsadd(
        this.number,
        this.name,
        this.tag,
        this.status,
        this.details,
        this.price,
        this.remark,
        this.url,
        this.session_key);
      console.log(res)
      console.log(this.url);
    },
    async update() {
      this.hide = false;
      const res = await Goodsedit(
        this.id,
        this.number,
        this.name,
        this.tag,
        this.status,
        this.details,
        this.price,
        this.remark,
        this.url,
        this.session_key);
      console.log(res)
    },
    empty() {
      this.Name = "";
    },
    // editorOption:{
    //     placeholder:'你想说什么？',
    //     modules: {
    //       imageResize: {
    //         displayStyles: {
    //           backgroundColor: 'black',
    //           border: 'none',
    //           color: 'white'
    //         },
    //         modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
    //       },
    //       toolbar: {
    //         // container: toolbarOptions,  // 工具栏
    //         handlers: {
    //           'image': function (value) {
    //             if (value) {
    //               document.querySelector('#quillEditorQiniu .avatar-uploader input').click()
    //             } else {
    //               this.quill.format('image', false);
    //             }
    //           },
    //           'video': function (value) {
    //             if (value) {
    //               document.querySelector('#quillEditorQiniu .avatar-uploader input').click()
    //             } else {
    //               this.quill.format('video', false);
    //             }
    //           },
    //         }
    //       }
    //     }
    //   },
    // onEditorBlur(event){
    //   console.log(event)
    // },
    // onEditorFocus(event){
    //   console.log(event)
    // },
    // onEditorReady(event){
    //   console.log(event)
    // },
    // onEditorChange(event){
    //   console.log(event)
    // }
  },
};
</script>
    
<style scoped>
li {
  list-style: none;
}
.editor{
  width: 780px;
  margin-left: 15px;
}
.detail p{
  text-align: left;
  margin-left: 152px;
  margin-bottom: 15px;
}
.center {
  width: auto;
  height: 100vh;
  margin: 60px 0 0 200px;
  background-color: #f2f6fc;
}

.center-top {
  width: 100%;
  height: 50px;
  background-color: #fff;
}

.top {
  margin: 20px 0 20px 30px;
}

.top i {
  padding-left: 5px;
  border-left: 2px solid #409eff;
}

.top span {
  margin-left: 10px;
  padding-left: 5px;
  border-left: 2px solid #ccc;
  font-size: 15px;
}

.main {
  margin: 20px 50px;
}

.btn {
  margin: 0 0 15px;
}

.bottom {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background-color: #fff;
  padding: 30px 0 20px;
}

.modal-box {
  position: relative;
  height: 650px;
  text-align: center;
  font-size: 18px;
}

.modal-box ul {
  margin-right: 200px;
  text-align: right;
}

.modal-box li {
  margin-bottom: 15px;
}

.modal-box input {
  width: 60%;
  height: 40px;
}

.modal-box-btn {
  position: absolute;
  bottom: 0;
  right: 30px;
}

.modal-box-btn button {
  width: 80px;
  height: 30px;
  margin-right: 20px;
}

.modal-box .modal-box-img {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.modal-box-img p {
  position: absolute;
  left: 150px;
  margin-right: 50px;
}

.modal-box-img div {
  margin-left: 88px;
}
</style>
  
  