import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '@/views/Login.vue'
import Home from '@/views/Home.vue'

import User from '@/views/User.vue'
import Address from '@/views/Address.vue'
import BillList from '@/views/BillList'
import Basic from '@/views/Basic.vue'
import Bill from '@/views/Bill.vue'

import Shopping from '@/views/Shopping.vue'
import EquiqMent from '@/views/Equiqment.vue'
import Order from '@/views/Order.vue'

import Manage from '@/views/Manage'
import Adminstrator from '@/views/Adminstrator'

import Usergroup from '@/views/Usergroup'
import Group from '@/views/Group'

import SheBei from '@/views/SheBei'
import ManageMent from '@/views/Management'

import Detail from '@/views/detail'

// import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
    routes: [
        { path: '/', redirect: '/login'},
        { path: '/login', component: Login},
        {
            path: '/home', component: Home,
            meta:{
                requiresAuth:true
            }
        },
        {
            path: '/user',
            component: User,
            redirect:'/user/basic',
            children: [
                {
                    path: '/user/basic',
                    component: Basic,
                    meta:{
                        requiresAuth:true
                    }
                },
                {
                    path: '/user/bill',
                    component: Bill,
                    meta:{
                        requiresAuth:true
                    }
                },
                {
                    path: '/user/address',
                    component: Address,
                    meta:{
                        requiresAuth:true
                    }
                },
                {
                    path: '/user/billlist',
                    component: BillList,
                    meta:{
                        requiresAuth:true
                    }
                }
                
            ]
        },
        {
            path: '/shopping',
            component: Shopping,
            redirect:'/shopping/equiqment',
            children: [
                {
                    path: '/shopping/equiqment',
                    component: EquiqMent,
                    meta:{
                        requiresAuth:true
                    }
                },
                {
                    path: '/shopping/order',
                    component: Order,
                    meta:{
                        requiresAuth:true
                    }
                },
                {
                    path: '/shopping/detail',
                    component: Detail,
                    meta:{
                        requiresAuth:true
                    }
                }
            ]
        },
        {
            path: '/manage',
            component: Manage,
            redirect:'/manage/adminstrator',
            children: [
                {
                    path: '/manage/adminstrator',
                    component: Adminstrator,
                    meta:{
                        requiresAuth:true
                    }
                }
            ]
        },
        {
            path: '/usergroup',
            component: Usergroup,
            redirect:'/usergroup/group',
            children: [
                {
                    path: '/usergroup/group',
                    component: Group,
                    meta:{
                        requiresAuth:true
                    }
                }
            ]
        },
        {
            path: '/device',
            component: SheBei,
            redirect:'/device/management',
            children: [
                {
                    path: '/device/management',
                    component: ManageMent,
                    meta:{
                        requiresAuth:true
                    }
                }
            ]
        }

    ],
    mode:'history'
})

// 全局前置导航守卫
router.beforeEach((to, from, next) => {
    if(to.meta.requiresAuth){
        var token =sessionStorage.getItem("session_key")
        if(token){
            next() 
        }else{
            next({
                path:"/login",
            }) 
        }
        
    }else{
        next();
    }
})

export default router