<template>
  <div class="box">
    <div class="center">
      <div class="center-top"></div>
      <div>
        <h6>收货地址</h6>
        <div class="main">
          <el-button class="btn" size="mini" type="primary" @click="handleAdd"
            >添加收货地址</el-button
          >
          <el-table
            :data="tableData"
            style="width: 100%"
            :header-cell-style="{ background: '#409eff', color: '#000' }"
          >
            <el-table-column label="ID" width="120">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.id }}</span>
              </template>
            </el-table-column>
            <el-table-column label="用户名" width="220">
              <template slot-scope="scope">
                <p>{{ scope.row.username }}</p>
              </template>
            </el-table-column>
            <el-table-column label="收货人姓名" width="220">
              <template slot-scope="scope">
                <p>{{ scope.row.name }}</p>
              </template>
            </el-table-column>
            <el-table-column label="手机号" width="220">
              <template slot-scope="scope">
                <p>{{ scope.row.phone }}</p>
              </template>
            </el-table-column>
            <el-table-column label="所属区域" width="220">
              <template slot-scope="scope">
                <p>{{ scope.row.Shipping_address }}</p>
              </template>
            </el-table-column>
            <el-table-column label="所属区域详细地址" width="220">
              <template slot-scope="scope">
                <p>{{ scope.row.Detailed_address }}</p>
              </template>
            </el-table-column>
            <el-table-column label="默认状态" width="220">
              <template slot-scope="scope">
                <p>{{ scope.row.defult == 1 ? "默认地址" : "不是默认地址" }}</p>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="handleEdit(scope.$index, scope.row,scope.row.id,scope.row.username,scope.row.name,scope.row.phone,scope.row.Shipping_address,scope.row.Detailed_address,scope.row.defult)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row,scope.row.id)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="bottom">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="arr"
              :page-size="pagesize"
              layout="total, sizes, prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="getmessage" width="45%" title="添加收货地址">
      <div class="modal-box">
        <ul>
            <li>用户名：<input type="text" v-model="username" /></li>
            <li>收货人姓名：<input type="text" v-model="name" /></li>
            <li>手机号：<input type="text" v-model="phone"/></li>
            <li>所属区域：<input type="text" v-model="Shipping_address"/></li>
            <li>所属区域详细地址：<input type="text" v-model="Detailed_address"/></li>
            <li>默认状态：<input type="text" v-model="defult"/></li>
        </ul>
        <div class="modal-box-btn">
          <button @click="add" type="primary">确定</button>
          <button @click="empty">重置</button>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="redact" width="45%" title="编辑收货地址">
      <div class="modal-box">
        <ul>
            <li>用户名：<input type="text" v-model="username" /></li>
            <li>收货人姓名：<input type="text" v-model="name" /></li>
            <li>手机号：<input type="text" v-model="phone"/></li>
            <li>所属区域：<input type="text" v-model="Shipping_address"/></li>
            <li>所属区域详细地址：<input type="text" v-model="Detailed_address"/></li>
            <li>默认状态：<input type="text" v-model="defult"/></li>
        </ul>
        <div class="modal-box-btn">
          <button @click="update" type="primary">修改</button>
          <button @click="empty">重置</button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getUseraddress,Useraddressadd,Useraddressedit,Useraddressdel } from "@/api/manage";
export default {
  name: "playletMusic",
  data() {
    return {
      todoName: "",
      total:0,  //总数据条数
      currentPage: 1,  //当前所在页默认是第一页
      pagesize:5,  //每页显示多少行数据 默认设置为5
      ticket:[],  //这里是从后端获取的所有数据
      arr:[5,10],
      tableData: [],
      getmessage: false,
      redact: false,
      id:'',
      username:'',
      name:'',
      phone:'',
      Shipping_address:'',
      Detailed_address:'',
      defult:'',
      session_key:''
    };
  },
  async created() {
    this.session_key = JSON.parse(sessionStorage.getItem("session_key"));
    this.getUseraddress()
  },
  methods: {
    getUseraddress (){
      getUseraddress().then((res) => {
        console.log(res)
        if(res.code===200){
          this.ticket = res.data.infor
            // //获取返回记录的总行数
            this.total = res.data.count
            // //获取当前页的数据
            this.getPageInfo()
        }else{
          console.log('失败')
        }
      })
    },
    

    // 获取当前页的数据信息
    getPageInfo (){
      //清空pageTicket中的数据
      this.tableData = []
      // 获取当前页的数据
      for(let i=(this.currentPage-1) * this.pagesize;i<this.total;i++){
        //把遍历的数据添加到pageTicket里面
        this.tableData.push(this.ticket[i]);
        //判断是否达到一页的要求
        if(this.tableData.length===this.pagesize) break;
      }
    },
    handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.pagesize = val
        this.getPageInfo()
      },
    handleCurrentChange (val) {
      console.log(`${val} `);
        this.currentPage = val
        this.getPageInfo()
    },
    handleAdd() {
      this.getmessage = true;
    },
    handleEdit(index, row,id,username,name,phone,Shipping_address,Detailed_address,defult) {
      console.log(index, row);
      this.id = id;
      this.username = username;
      this.name = name;
      this.phone = phone;
      this.Shipping_address = Shipping_address;
      this.Detailed_address= Detailed_address;
      this.defult = defult;
      
      this.redact = true;
    },
    async handleDelete(index, row,id) {
      console.log(index, row,id);
        const res = await Useraddressdel(id,this.session_key)
        console.log(res)
      // this.tableData = this.tableData.filter((item) => item.id !== index);
    },
    async add() {
      // if (this.todoName.trim() === "") {
      //   alert("请输入名称");
      //   return;
      // }
      // this.tableData.push({
      //   id: +new Date(),
      //   name: this.todoName,
      // });
      this.todoName = "";
      this.getmessage = false;
      const res = await Useraddressadd(
        this.username,
        this.name,
        this.phone,
        this.Shipping_address,
        this.Detailed_address,
        this.defult,
        this.session_key);
        console.log(res)
    },
    empty() {
      this.todoName = "";
    },
    async update() {
      this.redact = false;
      const res = await Useraddressedit(
        this.id,
        this.username,
        this.name,
        this.phone,
        this.Shipping_address,
        this.Detailed_address,
        this.defult,
        this.session_key);
        console.log(res)
    },
  },
};
</script>

<style scoped>
.modal-box {
  position: relative;
  height: 450px;
  text-align: center;
  font-size: 18px;
}
.modal-box ul {
    margin-right: 200px;
    text-align: right;
  }
  .modal-box li {
    margin-bottom: 15px;
  }
.modal-box input {
  width: 60%;
  height: 40px;
}
.modal-box-btn {
  position: absolute;
  bottom: 0;
  right: 30px;
}
.modal-box-btn button {
  width: 80px;
  height: 30px;
  margin-right: 20px;
}
.center {
  width: auto;
  height: 100vh;
  margin: 60px 0 0 200px;
  background-color: #f2f6fc;
}
.center-top {
  width: 100%;
  height: 50px;
  background-color: #fff;
}

h6 {
  margin: 20px 30px;
  padding-left: 10px;
  border-left: 2px solid #409eff;
  font-size: 15px;
}
.main {
  margin: 0 50px 20px;
}
.btn {
  margin: 0 0 15px;
}
.bottom {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background-color: #fff;
  padding: 30px 0 20px;
}
</style>