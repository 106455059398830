<template>
  <div>
    <div class="center">
      <div class="center-top"></div>
      <div class="top">
        <i class="el-icon-house"></i>
        <span>商品管理</span>
        <span>订单</span>
      </div>
      <div class="main">
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#409eff', color: '#000' }"
        >
          <el-table-column label="ID" width="50">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.id }}</span>
            </template>
          </el-table-column>
          <el-table-column label="订单编号" width="135">
            <template slot-scope="scope">
              <p>{{ scope.row.number }}</p>
            </template>
          </el-table-column>
          <el-table-column label="订单状态" width="110">
            <template slot-scope="scope">
              <p v-if="scope.row.status == 0">待支付</p>
              <p v-else-if="scope.row.status == 1">已支付</p>
              <p v-else-if="scope.row.status == 2">已发货</p>
              <p v-else-if="scope.row.status == 3">已完成</p>
            </template>
          </el-table-column>
          <el-table-column label="用户名" width="110">
            <template slot-scope="scope">
              <p>{{ scope.row.name }}</p>
            </template>
          </el-table-column>
          <el-table-column label="手机号" width="120">
            <template slot-scope="scope">
              <p>{{ scope.row.phone }}</p>
            </template>
          </el-table-column>
          <el-table-column label="购买数量" width="110">
            <template slot-scope="scope">
              <p>{{ scope.row.quantity }}</p>
            </template>
          </el-table-column>
          <el-table-column label="总价" width="110">
            <template slot-scope="scope">
              <p>{{ scope.row.price }}</p>
            </template>
          </el-table-column>
          <el-table-column label="订单创建时间" width="135">
            <template slot-scope="scope">
              <p>{{ scope.row.time }}</p>
            </template>
          </el-table-column>
          <el-table-column label="支付时间" width="135">
            <template slot-scope="scope">
              <p>{{ scope.row.update_time}}</p>
            </template>
          </el-table-column>
          <el-table-column label="备注" width="120">
            <template slot-scope="scope">
              <p>{{ scope.row.remark }}</p>
            </template>
          </el-table-column>
          <el-table-column label="快递类型" width="120">
            <template slot-scope="scope">
              <p>{{ scope.row.transport == 1 ? '门店配送' : '普通快递' }}</p>
            </template>
          </el-table-column>
          <el-table-column label="配送地址" width="150">
            <template slot-scope="scope">
              <p>{{ scope.row.Shipping_address}}{{ scope.row.Detailed_address }}</p>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="detail(scope.row.id)"
                >详情</el-button
              >
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row,scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="bottom">
          <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="arr"
              :page-size="pagesize"
              layout="total, sizes, prev, pager, next"
              :total="total">
            </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import { getGoodsorder,Goodsorderdel } from '@/api/manage'
export default {
  name: "AuditorMu",
  data() {
    return {
      Name:'',
      total:0,  //总数据条数
      currentPage: 1,  //当前所在页默认是第一页
      pagesize:5,  //每页显示多少行数据 默认设置为5
      ticket:[],  //这里是从后端获取的所有数据
      arr:[5,10],
      tableData: [],
      show: false,
      hide:false,
      id:1,
      name:'',
      session_key:''
    };
  },
  async created () {
    this.session_key = JSON.parse(sessionStorage.getItem("session_key"));
    this.getGoodsorder();
  },
  methods: {
    detail(id){
      this.$router.push({
        path: '/shopping/detail',
        query: {
          id: `${id}`,
          token: `${this.session_key}`
        }
      })
    },
    getGoodsorder() {
      getGoodsorder().then((res) => {
        console.log(res)
        if(res.code===200){
          this.ticket = res.data.infor
            // //获取返回记录的总行数
            this.total = res.data.count
            // //获取当前页的数据
            this.getPageInfo()
        }else{
          console.log('失败')
        }
      })
    },
    // 获取当前页的数据信息
    getPageInfo (){
      //清空pageTicket中的数据
      this.tableData = []
      // 获取当前页的数据
      for(let i=(this.currentPage-1) * this.pagesize;i<this.total;i++){
        //把遍历的数据添加到pageTicket里面
        this.tableData.push(this.ticket[i]);
        //判断是否达到一页的要求
        if(this.tableData.length===this.pagesize) break;
      }
    },
    handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.pagesize = val
        this.getPageInfo()
      },
      handleCurrentChange (val) {
      console.log(`${val} `);
        this.currentPage = val
        this.getPageInfo()
    },
    handleAdd(){
      this.show = true;
    },
    async handleDelete(index, row, id) {
        console.log(index, row, id);
        const res = await Goodsorderdel(id,this.session_key)
        console.log(res)
      },
    empty() {
      this.Name = "";
    },
  },
};
</script>
    
<style scoped>
li {
  list-style: none;
}
.center {
  width: auto;
  height: 100vh;
  margin: 60px 0 0 200px;
  background-color: #f2f6fc;
}
.center-top {
  width: 100%;
  height: 50px;
  background-color: #fff;
}
.top {
  margin: 20px 0 20px 30px;
}
.top i {
  padding-left: 5px;
  border-left: 2px solid #409eff;
}
.top span {
  margin-left: 10px;
  padding-left: 5px;
  border-left: 2px solid #ccc;
  font-size: 15px;
}
.main {
  margin: 20px 50px;
}
.btn {
  margin: 0 0 15px;
}
.bottom {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background-color: #fff;
  padding: 30px 0 20px;
}
.modal-box {
  position: relative;
  height: 650px;
  text-align: center;
  font-size: 18px;
}
.modal-box ul {
  margin-right: 200px;
  text-align: right;
}
.modal-box li {
  margin-bottom: 15px;
}
.modal-box input {
  width: 60%;
  height: 40px;
}
.modal-box-btn {
  position: absolute;
  bottom: 0;
  right: 30px;
}
.modal-box-btn button {
  width: 80px;
  height: 30px;
  margin-right: 20px;
}
</style>
  
  