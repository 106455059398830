<template>
  <div>
    <TimeHead></TimeHead>
    <div class="left">
      <ul>
        <li><router-link to="/user/basic">基本信息</router-link></li>
        <li><router-link to="/user/bill">银行信息</router-link></li>
        <li><router-link to="/user/address">收货地址</router-link></li>
        <li><router-link to="/user/billlist">账单列表</router-link></li>
      </ul>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "UserIndex",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style  scoped>
.left {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  background-color: #e1e6f0;
}
.left ul {
  margin-top: 130px;
  text-align: center;
}
.left a {
  display: block;
  width: 100%;
  height: 60px;
  line-height: 60px;
  color: #000;
}
.left a.router-link-active {
  color: #3370ff;
  background-color: rgba(0, 0, 0, 0.1);
}
</style>