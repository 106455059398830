
import axios from 'axios'
//创建 axios 实例，将来对创建出来的实例，进行自定义
//好处：不会污染原始的 axios 实例
const instance = axios.create({
	// baseURL: 'http://cba.itlike.com/public/index.php?s=/api/',
	baseURL: 'https://sgy.yurunshengwu.cn/backend',
	timeout: 5000
});

//自定义
// 添加请求拦截器
instance.interceptors.request.use(function(config) {
	// 在发送请求之前做些什么
	return config;
}, function(error) {
	// 对请求错误做些什么
	return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function(response) {
	// 2xx 范围内的状态码都会触发该函数。
	// 对响应数据做点什么 (默认 axios 会多包一层 data ，需要拦截响应器中处理一下)
	const res = response.data
	if(res.code !== 200){
		return Promise.reject(res.msg)
	}
	return res
}, function(error) {
	// 超出 2xx 范围的状态码都会触发该函数。
	// 对响应错误做点什么
	return Promise.reject(error);
});

//导出
export default instance
