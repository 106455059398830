import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from './router/index'
import TimeHead from './components/TimeHead'
import TimeLeft from './components/TimeLeft'
import store from './store/index.js'
import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

Vue.use(VueQuillEditor)

// import { VueQuillEditor } from 'vue-quill-editor'
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.show.css'
// import 'quill/dist/quill.bubble.css'
// Vue.use(VueQuillEditor)
// import * as Quill from 'quill'
// import { ImageDrop } from 'quill-image-drop-module'
// Quill.register('modules/imageDrop',ImageDrop)
// import ImageResize from 'quill-image-resize-module'
// Quill.register('modules/imageResize',ImageResize)

Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.component('TimeHead',TimeHead)
Vue.component('TimeLeft',TimeLeft)

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')

