<template>
  <div>
    <TimeHead></TimeHead>
    <div class="left">
      <ul>
        <li><router-link to="/home">欢迎来到首页</router-link></li>
      </ul>
    </div>
    <div class="center">
      <div class="center-top">首页</div>
      <!-- <div style="border: 1px solid #ccc;
      margin-top: 100px;">
    </div> -->
    </div>
  </div>
</template>

<script>
// import { getIndex } from "@/api/manage";

export default {

    name:'TimeHome',
    data() {
    return {
      // editor: null,
      // html: '<p>hello</p >',
      // toolbarConfig: { },
      // editorConfig: { placeholder: '请输入内容...' },
      // mode: 'default', // or 'simple'
    };
  },
  // methods: {
  //       onCreated(editor) {
  //           this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
  //       },
  //   },
  //   mounted() {
  //       // 模拟 ajax 请求，异步渲染编辑器
  //       setTimeout(() => {
  //           this.html = '<p>模拟 Ajax 异步设置内容 HTML</p >'
  //       }, 1500)
  //   },
  //   beforeDestroy() {
  //       const editor = this.editor
  //       if (editor == null) return
  //       editor.destroy() // 组件销毁时，及时销毁编辑器
  //   }
}
</script>

<!--   src="@wangeditor/editor/dist/css/style.css" -->
<style  scoped>

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.left {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px !important;
  height: 100%;
  background-color: #e1e6f0;
}
.left ul {
  margin-top: 130px;
  text-align: center;
}
.left a {
  display: block;
  width: 100%;
  height: 60px;
  line-height: 60px;
  color: #000;
}
.left a.router-link-active {
  color: #3370ff;
  background-color: rgba(0, 0, 0, 0.1);
}
.center {
  width: 500px !important;
  height: 100vh;
  margin: 60px 0 0 200px;
  background-color: #f2f6fc;
}
.center-top {
  width: 100%;
  height: 50px;
  background-color: #fff;
}
.nav-li span {
  display: block;
  margin-top: 20px;
}

.center1 {
  padding: 20px;
}
.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 150px;
  padding: 25px 20px;
  background-color: #fff;
  
}
.nav-item {
  width: 15.5%;
  height: 100px;
  padding: 15px 10px 0 0;
  border-right: 2px solid #ccc;
  font-size: 14px;
}
.nav div:last-child {
  border: none;
}
.nav img {
  float: left;
  margin: 15px 10px 0 0;
}
.nav h6 {
  margin: 12px 0 10px;
  font-size: 16px;
}
.user {
  margin: 20px 0;
  overflow: hidden;
}
.grid {
  width: 100%;
  height: 200px;
  padding: 20px;
  background-color: #fff;
}
.bg {
  width: 100%;
  margin-left: 20px;
  padding: 20px;
  height: 200px;
  background-color: #fff;
}
.user-top li {
  float: left;
  font-size: 18px;
  font-weight: bold;
  margin: -10px 15px 0 10px;
}
.user-top li::marker {
  color: blue;
  font-size: 25px;
}
.list {
  clear: both;
  text-align: center;
  margin-top: 40px;
  font-size: 15px;
}

.list span {
  display: inline-block;
  margin-bottom: 8px;
  color: #62a1ec;
  font-size: 22px;
}
.list img {
  width: 40px;
}
.list p {
  color: #62a1ec;
  margin-top: 10px;
}
.table {
  clear: both;
  border: 1px solid #ccc;
  margin: 20px 20px 0 0;
}
.table li{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
}
.table li:first-child{
  border-bottom: 1px solid #ccc;
}
.footer {
  width: 100%;
  height: 260px;
  padding: 20px;
  background-color: #fff;
}
.footer-inp {
  clear: both;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: #ececec;
  padding-left: 20px;
  margin-top: 10px;
}
.footer-inp span {
  margin-right: 30px;
}
.footer-list {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #ccc;
}
.footer-list span {
  margin: 0 25px;
}
</style>