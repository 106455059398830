// import { getInfo, setInfo } from '@/utils/storage'
export default{
    namespaced: true,
    state (){
        return {
            // userInfo:getInfo
        }
    },
    mutations: {
        // setToken (state, obj){
        //     state.userInfo = obj
        //     setInfo(obj)
        // }
    },
    actions: {}
}