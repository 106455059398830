<template>
    <div>
      <div class="center">
        <div class="center-top"></div>
        <div class="top">
          <i class="el-icon-house"></i>
          <span>用户管理</span>
          <span>基本信息</span>
        </div>
        <div class="main">
          <el-button class="btn" size="mini" type="primary" @click="Add"
            >添加基本信息</el-button
          >
          <el-table
            :data="tableData"
            style="width: 100%"
            :header-cell-style="{ background: '#409eff', color: '#000' }"
          >
            <el-table-column label="ID" width="70">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.id }}</span>
              </template>
            </el-table-column>
            <el-table-column label="用户名" width="90">
              <template slot-scope="scope">
                <p>{{ scope.row.username }}</p>
              </template>
            </el-table-column>
            <el-table-column label="手机号码" width="130">
              <template slot-scope="scope">
                <p>{{ scope.row.phone }}</p>
              </template>
            </el-table-column>
            <el-table-column label="用户地址" width="130">
              <template slot-scope="scope">
                <p>{{ scope.row.address }}</p>
              </template>
            </el-table-column>
            <el-table-column label="用户身份证号码" width="150">
              <template slot-scope="scope">
                <p>{{ scope.row.IC_number }}</p>
              </template>
            </el-table-column>
            <el-table-column label="用户身份姓名" width="130">
              <template slot-scope="scope">
                <p>{{ scope.row.IC_name }}</p>
              </template>
            </el-table-column>
            <el-table-column label="用户余额" width="130">
              <template slot-scope="scope">
                <p>{{ scope.row.balance }}</p>
              </template>
            </el-table-column>
            <el-table-column label="openid" width="160">
              <template slot-scope="scope">
                <p>{{ scope.row.openid }}</p>
              </template>
            </el-table-column>
            <el-table-column label="推荐人id" width="130">
              <template slot-scope="scope">
                <p>{{ scope.row.oneid }}</p>
              </template>
            </el-table-column>
            <el-table-column label="所属组名" width="130">
              <template slot-scope="scope">
                <p>{{ scope.row.name }}</p>
              </template>
            </el-table-column>
            <el-table-column label="等级" width="100">
              <template slot-scope="scope">
                <p>{{ scope.row.level }}</p>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="handleEdit(scope.$index,scope.row, scope.row.id,scope.row.username,
                  scope.row.phone,scope.row.address,scope.row.IC_number,scope.row.IC_name,scope.row.balance,
                  scope.row.oneid,scope.row.level)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index,scope.row, scope.row.id)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="bottom">
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="arr"
                :page-size="pagesize"
                layout="total, sizes, prev, pager, next"
                :total="total">
              </el-pagination>
          </div>
        </div>
      </div>
      <el-dialog :visible.sync="show" class="dialog" width="45%" title="添加基本信息">
        <div class="modal-box">
          <ul>
            <li>用户名：<input type="text" v-model="username" /></li>
            <li>登录密码：<input type="password" v-model="password" /></li>
            <li>手机号码：<input type="text" v-model="phone" /></li>
            <li>用户地址：<input type="text" v-model="address" /></li>
            <li>用户身份证号码：<input type="text" v-model="IC_number"/></li>
            <li>用户身份姓名：<input type="text" v-model="IC_name"/></li>
            <li>用户余额：<input type="text" v-model="balance"/></li>
            <li>推荐人id：<input type="text" v-model="oneid"/></li>
            <li>支付密码：<input type="password" v-model="payword" /></li>
            <li>等级：<input type="text" v-model="level"/></li>
          </ul>
          <div class="modal-box-btn">
            <button @click="postadd" type="primary">确定</button>
            <button @click="empty">重置</button>
          </div>
        </div>
      </el-dialog>
      <el-dialog :visible.sync="hide" class="dialog" width="45%" title="修改基本信息">
        <div class="modal-box">
          <ul>
            <li>用户名：<input type="text" v-model="username" /></li>
            <li>登录密码：<input type="password" v-model="password" /></li>
            <li>手机号码：<input type="text" v-model="phone" /></li>
            <li>用户地址：<input type="text" v-model="address" /></li>
            <li>用户身份证号码：<input type="text" v-model="IC_number"/></li>
            <li>用户身份姓名：<input type="text" v-model="IC_name"/></li>
            <li>用户余额：<input type="text" v-model="balance"/></li>
            <li>推荐人id：<input type="text" v-model="oneid"/></li>
            <li>支付密码：<input type="password" v-model="payword" /></li>
            <li>等级：<input type="text" v-model="level"/></li>
          </ul>
          <div class="modal-box-btn">
            <button @click="update" type="primary">修改</button>
            <button @click="empty">重置</button>
          </div>
        </div>
      </el-dialog>
    </div>
  </template>
    
    <script>
  import { getUser,UserAdd,Useredit,Userdel } from "@/api/manage";
  export default {
    name: "BasicUser",
    data() {
      return {
        Name: "",
        total:0,  //总数据条数
        currentPage: 1,  //当前所在页默认是第一页
        pagesize:5,  //每页显示多少行数据 默认设置为5
        ticket:[],  //这里是从后端获取的所有数据
        arr:[5],
        tableData: [],
        show: false,
        hide: false,
        id:'',
        username:'',
        password:'',
        phone:'',
        address:'',
        IC_number:'',
        IC_name:'',
        balance:'',
        openid:'',
        token:'',
        oneid:'',
        payword:'',
        level:'',
        session_key:''
      };
    },
    async created() {
      this.session_key = JSON.parse(sessionStorage.getItem("session_key"));
      console.log(this.session_key);
      this.getUser();
    },
    methods: {
      getUser (){
        
        getUser().then((res) => {
          console.log(res)
          if(res.code===200){
            this.ticket = res.data.infor
            // //获取返回记录的总行数
            this.total = res.data.count
            // //获取当前页的数据
            this.getPageInfo()
          }else{
            console.log('失败')
          }
        })
      },
      // 获取当前页的数据信息
      getPageInfo (){
        //清空pageTicket中的数据
        this.tableData = []
        // 获取当前页的数据
        for(let i=(this.currentPage-1) * this.pagesize;i<this.total;i++){
          //把遍历的数据添加到pageTicket里面
          this.tableData.push(this.ticket[i]);
          //判断是否达到一页的要求
          if(this.tableData.length===this.pagesize) break;
        }
      },
      handleSizeChange(val) {
          console.log(`每页 ${val} 条`);
          this.pagesize = val
          this.getPageInfo()
        },
      handleCurrentChange (val) {
        console.log(`${val} `);
          this.currentPage = val
          this.getPageInfo()
      },
      Add() {
        this.show = true;

      },
      handleEdit(index,row, id,username,phone,address,IC_number,IC_name,balance,oneid,payword,level) {
        console.log(index,row, id);
        this.id= id;
        this.username = username;
        this.phone = phone;
        this.address = address;
        this.IC_number = IC_number;
        this.IC_name = IC_name;
        this.balance = balance;
        this.oneid = oneid;
        this.payword = payword;
        this.level = level;
        this.update();
        this.hide = true;
      },
      async handleDelete(index, row, id) {
        console.log(index, row, id);
        const res = await Userdel(id,this.session_key)
        console.log(res)
      },
  
      async postadd() {
        this.Name = "";
        this.show = false;
        const res = await UserAdd(
          this.username,
          this.password,
          this.phone,
          this.address,
          this.IC_number,
          this.IC_name,
          this.balance,
          this.oneid,
          this.payword,
          this.level,
          this.session_key);
        console.log(res)
        this.password = '';
        this.payword = '';
      },

      async update() {
        this.hide = false;
        
        if(this.password == '' || this.payword == ''){
          const res = await Useredit(
          this.id,
          this.username,
          this.phone,
          this.address,
          this.IC_number,
          this.IC_name,
          this.balance,
          this.oneid,
          this.level,
          this.session_key);
          
        console.log(res)
        }else{
          const res = await Useredit(
          this.id,
          this.username,
          this.password,
          this.phone,
          this.address,
          this.IC_number,
          this.IC_name,
          this.balance,
          this.oneid,
          this.payword,
          this.level,
          this.session_key);
          
        console.log(res)
        }
        
      },

      empty() {
        this.Name = "";
      },
    },
  };
  </script>
    
  <style scoped>
  li {
    list-style: none;
  }
  .modal-box {
    position: relative;
    padding: 30px 0 80px;
    text-align: center;
    font-size: 18px;
  }
  .modal-box ul {
    margin-right: 200px;
    text-align: right;
  }
  .modal-box li {
    margin-bottom: 15px;
  }
  .modal-box .modal-box-img {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .modal-box-img p {
    position: absolute;
    left: 210px;
    margin-right: 50px;
  }
  .modal-box-img div {
    margin-left: 88px;
  }
  .modal-box input {
    width: 60%;
    height: 40px;
  }
  .modal-box-btn {
    position: absolute;
    bottom: 0;
    right: 30px;
  }
  .modal-box-btn button {
    width: 80px;
    height: 30px;
    margin-right: 20px;
  }
  .center {
    width: auto;
    height: 100vh;
    margin: 60px 0 0 200px;
    background-color: #f2f6fc;
  }
  .center-top {
    width: 100%;
    height: 50px;
    background-color: #fff;
  }
  
  .top {
    margin: 20px 0 20px 30px;
  }
  .top i {
    padding-left: 5px;
    border-left: 2px solid #409eff;
  }
  .top span {
    margin-left: 10px;
    padding-left: 5px;
    border-left: 2px solid #ccc;
    font-size: 15px;
  }
  .main {
    margin: 20px 50px;
  }
  .btn {
    margin: 0 0 15px;
  }
  .bottom {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    background-color: #fff;
    padding: 30px 0 20px;
  }
  </style>
  
  