<template>
    <div>
      <div class="center">
        <div class="center-top"></div>
        <div class="top">
          <i class="el-icon-house"></i>
          <span>用户管理</span>
          <span>银行信息</span>
        </div>
        <div class="main">
          <el-button class="btn" size="mini" type="primary" @click="Add"
            >添加银行信息</el-button
          >
          <el-table
            :data="tableData"
            style="width: 100%"
            :header-cell-style="{ background: '#409eff', color: '#000' }"
          >
            <el-table-column label="ID" width="180">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.id }}</span>
              </template>
            </el-table-column>
            <el-table-column label="用户名" width="200">
              <template slot-scope="scope">
                <p>{{ scope.row.username }}</p>
              </template>
            </el-table-column>
            <el-table-column label="开户行用户地址" width="200">
              <template slot-scope="scope">
                <p>{{ scope.row.address }}</p>
              </template>
            </el-table-column>
            <el-table-column label="银行卡名字" width="200">
              <template slot-scope="scope">
                <p>{{ scope.row.name }}</p>
              </template>
            </el-table-column>
            <el-table-column label="银行卡号" width="200">
              <template slot-scope="scope">
                <p>{{ scope.row.number }}</p>
              </template>
            </el-table-column>
            <el-table-column label="用户银行真名" width="200">
              <template slot-scope="scope">
                <p>{{ scope.row.realname }}</p>
              </template>
            </el-table-column>
            <el-table-column label="预留手机号码" width="200">
              <template slot-scope="scope">
                <p>{{ scope.row.phone }}</p>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="handleEdit(scope.$index, scope.row,scope.row.id,scope.row.username,scope.row.address,scope.row.name,scope.row.number,scope.row.realname,scope.row.phone)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row,scope.row.id)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="bottom">
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="arr"
                :page-size="pagesize"
                layout="total, sizes, prev, pager, next"
                :total="total">
              </el-pagination>
          </div>
        </div>
      </div>
      <el-dialog :visible.sync="show" class="dialog" width="45%" title="添加银行信息">
        <div class="modal-box">
          <ul>
            <li>用户名：<input type="text" v-model="username"/></li>
            <li>开户行用户地址：<input type="text" v-model="address"/></li>
            <li>银行卡名字：<input type="text" v-model="name"/></li>
            <li>银行卡号：<input type="text" v-model="number"/></li>
            <li>银行卡真名：<input type="text" v-model="realname"/></li>
            <li>预留手机号：<input type="text" v-model="phone"/></li>
          </ul>
          <div class="modal-box-btn">
            <button @click="postadd" type="primary">确定</button>
            <button @click="empty">重置</button>
          </div>
        </div>
      </el-dialog>
      <el-dialog :visible.sync="hide" class="dialog" width="45%" title="修改银行信息">
        <div class="modal-box">
          <ul>
            <li>用户名：<input type="text" v-model="username"/></li>
            <li>开户行用户地址：<input type="text" v-model="address"/></li>
            <li>银行卡名字：<input type="text" v-model="name"/></li>
            <li>银行卡号：<input type="text" v-model="number"/></li>
            <li>银行卡真名：<input type="text" v-model="realname"/></li>
            <li>预留手机号：<input type="text" v-model="phone"/></li>
          </ul>
          <div class="modal-box-btn">
            <button @click="update" type="primary">修改</button>
            <button @click="empty">重置</button>
          </div>
        </div>
      </el-dialog>
    </div>
  </template>
    
    <script>
  import { getIcard,Icardadd,Icardedit,Icarddel } from "@/api/manage";
  export default {
    name: "BillUser",
    data() {
      return {
        Name: "",
        total:0,  //总数据条数
        currentPage: 1,  //当前所在页默认是第一页
        pagesize:5,  //每页显示多少行数据 默认设置为5
        ticket:[],  //这里是从后端获取的所有数据
        arr:[5],
        tableData: [],
        show: false,
        hide: false,
        id:'',
        username:'',
        address:'',
        name:'',
        number:'',
        realname:'',
        phone:'',
        session_key:''
      };
    },
    async created() {
      this.session_key = JSON.parse(sessionStorage.getItem("session_key"));
      this.getIcard();
    },
    methods: {
      getIcard (){
        getIcard().then((res) => {
          console.log(res)
          if(res.code===200){
            this.ticket = res.data.infor
            // //获取返回记录的总行数
            this.total = res.data.count
            // //获取当前页的数据
            this.getPageInfo()
          }else{
            console.log('失败')
          }
        })
      },
      // 获取当前页的数据信息
      getPageInfo (){
        //清空pageTicket中的数据
        this.tableData = []
        // 获取当前页的数据
        for(let i=(this.currentPage-1) * this.pagesize;i<this.total;i++){
          //把遍历的数据添加到pageTicket里面
          this.tableData.push(this.ticket[i]);
          //判断是否达到一页的要求
          if(this.tableData.length===this.pagesize) break;
        }
      },
      handleSizeChange(val) {
          console.log(`每页 ${val} 条`);
          this.pagesize = val
          this.getPageInfo()
        },
      handleCurrentChange (val) {
        console.log(`${val} `);
          this.currentPage = val
          this.getPageInfo()
      },
      Add() {
        this.show = true;
      },
      handleEdit(index, row,id,username,address,name,number,realname,phone) {
        console.log(index, row);
        this.id = id;
        this.username = username;
        this.address = address;
        this.name = name;
        this.number = number;
        this.realname = realname;
        this.phone = phone;
        this.hide = true;
      },
      async handleDelete(index, row, id) {
        console.log(index, row, id);
        const res = await Icarddel(id,this.session_key)
        console.log(res)
      },
  
      async postadd() {
        this.Name = "";
        this.show = false;
        const res = await Icardadd(
          this.username,
          this.address,
          this.name,
          this.number,
          this.realname,
          this.phone,
          this.session_key);
        console.log(res)
      },
      async update() {
        this.hide = false;
        const res = await Icardedit(
          this.id,
          this.username,
          this.address,
          this.name,
          this.number,
          this.realname,
          this.phone,
          this.session_key);
        console.log(res)
      },
      empty() {
        this.Name = "";
      },
    },
  };
  </script>
    
  <style scoped>
  li {
    list-style: none;
  }
  .modal-box {
    position: relative;
    height: 450px;
    text-align: center;
    font-size: 18px;
  }
  .modal-box ul {
    margin-right: 200px;
    text-align: right;
  }
  .modal-box li {
    margin-bottom: 15px;
  }
  .modal-box .modal-box-img {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .modal-box-img p {
    position: absolute;
    left: 210px;
    margin-right: 50px;
  }
  .modal-box-img div {
    margin-left: 88px;
  }
  .modal-box input {
    width: 60%;
    height: 40px;
  }
  .modal-box-btn {
    position: absolute;
    bottom: 0;
    right: 30px;
  }
  .modal-box-btn button {
    width: 80px;
    height: 30px;
    margin-right: 20px;
  }
  .center {
    width: auto;
    height: 100vh;
    margin: 60px 0 0 200px;
    background-color: #f2f6fc;
  }
  .center-top {
    width: 100%;
    height: 50px;
    background-color: #fff;
  }
  
  .top {
    margin: 20px 0 20px 30px;
  }
  .top i {
    padding-left: 5px;
    border-left: 2px solid #409eff;
  }
  .top span {
    margin-left: 10px;
    padding-left: 5px;
    border-left: 2px solid #ccc;
    font-size: 15px;
  }
  .main {
    margin: 20px 50px;
  }
  .btn {
    margin: 0 0 15px;
  }
  .bottom {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    background-color: #fff;
    padding: 30px 0 20px;
  }
  </style>
  
  