<template>
    <div class="center">
        
        <div class="list">
            <h6>订单基本信息</h6>
            <ul v-for="item in infor" :key="item.id">
                <li>
                    <span>订单编号</span>
                    <p>{{item.number}}</p>
                </li>
                <li>
                    <span>订单状态</span>
                    <p v-if="item.status == 0">待支付</p>
                    <p v-else-if="item.status == 1">已支付</p>
                    <p v-else-if="item.status == 2">已发货</p>
                    <p v-else-if="item.status == 3">已完成</p>
                </li>
                <li>
                    <span>购买数量</span>
                    <p>{{item.quantity}}</p>
                </li>
                <li>
                    <span>总价</span>
                    <p>{{item.price}}</p>
                </li>
                <li>
                    <span>订单提交时间</span>
                    <p>{{item.time}}</p>
                </li>
                <li>
                    <span>支付时间</span>
                    <p>{{item.update_time}}</p>
                </li>
                <li>
                    <span>备注</span>
                    <p>{{item.remark}}</p>
                </li>
                <li v-for="item in address" :key="item.id">
                    <span>配送地址</span>
                    <p>{{item.Shipping_address}}</p>
                </li>
                <li v-for="item in address" :key="item.id">
                    <span>详细地址</span>
                    <p>{{item.Detailed_address}}</p>
                </li>
                <li v-for="item in address" :key="item.id">
                    <span>用户名</span>
                    <p>{{item.name}}</p>
                </li>
            </ul>
        </div>
        <div >
            <el-table
            :data="tableData"
            style="width: 100%"
            :header-cell-style="{ background: '#e1e6f0', color: '#000' }">
            <el-table-column label="图片路径" width="200">
            <template slot-scope="scope">
              <el-image style="width: 80px; height: 80px" :src="scope.row.url"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="商品名称" width="200">
            <template slot-scope="scope">
              <p>{{ scope.row.name }}</p>
            </template>
          </el-table-column>
          <el-table-column label="单价" width="200">
            <template slot-scope="scope">
              <p>{{ scope.row.price }}</p>
            </template>
          </el-table-column>
          <el-table-column label="数量" width="200">
            <template>
              <p v-for="item in infor" :key="item.id">{{ item.quantity }}</p>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="200">
            <template slot-scope="scope">
                <p v-if="scope.row.status == 0">待支付</p>
                <p v-else-if="scope.row.status == 1">已支付</p>
                <p v-else-if="scope.row.status == 2">已发货</p>
                <p v-else-if="scope.row.status == 3">已完成</p>
            </template>
          </el-table-column>
          <el-table-column label="总价" width="200">
            <template slot-scope="scope">
              <p v-for="item in infor" :key="item.id">{{ scope.row.price }}</p>
            </template>
          </el-table-column>
            </el-table>
        </div>
        <div class="list" v-if="store">
            <h6>{{type}}</h6>
            <ul v-for="item in address" :key="item.id">
                <li>
                    <span>收货人</span>
                    <p>{{item.name}}</p>
                </li>
                <li>
                    <span>联系电话</span>
                    <p>{{item.phone}}</p>
                </li>
                <li>
                    <span>地址</span>
                    <p>{{item.Shipping_address}}{{ item.Detailed_address }}</p>
                </li>
                <li>
                    <span>现在物流</span>
                    <p>快递配送</p>
                </li>
                <li>
                    <span>运单号</span>
                    <p>
                        <input v-model="hwb" type="text" style="width: 300px;height: 30px;">
                    </p>
                </li>
            </ul>
            <div class="btn">
                <!-- <span>保存发货信息</span> -->
                <span @click="deliver">确认发货</span>
            </div>
        </div>
        <div class="list" v-else>
            <h6>{{types}}</h6>
            <ul  v-for="item in address" :key="item.id">
                <li>
                    <span>收货人</span>
                    <p>{{item.name}}</p>
                </li>
                <li>
                    <span>联系电话</span>
                    <p>{{item.phone}}</p>
                </li>
                <li>
                    <span>地址</span>
                    <p>{{item.Shipping_address}}{{ item.Detailed_address }}</p>
                </li>
                <li>
                    <span>核销码</span>
                    <p>546</p>
                </li>
            </ul>
        </div>
    </div>
</template>
    
<script>
import { findOutMore,shipments } from '@/api/manage'
  export default {
    name: "BillList",
    data() {
      return {
        tableData: [],
          id:1,
          transport:'',
          number:'',
          type:'快递配送',
          types:'门店自提',
          hwb:'',
          session_key:'',
          store:true,
          infor:[],
          address:[]
      };
    },
    created(){
        this.session_key = this.$route.query.token
        this.id = this.$route.query.id
        console.log(this.$route.query.id)
        console.log(this.$route.query.token)
        this.detail();
    },
    methods: {
        async detail(){
            const res = await findOutMore(this.id,this.session_key)
            console.log(res)
            this.infor = res.data.infor
            this.address = res.data.address
            this.tableData = res.data.goods  
            this.number = res.data.infor[0].number
            console.log(res.data.infor[0].transport)
            if(res.data.infor[0].transport == 1){
                this.store = false
            }
        },
        async deliver(){
            const res = await shipments(this.number,this.type,this.hwb,this.session_key)
            console.log(res)
        }
    }
  };
</script>
    
<style scoped>
li {
  list-style: none;
}
.center {
  width: 95%;
  height: 100vh;
  padding: 80px 0 0 250px;
  background-color: #f2f6fc;
  box-sizing: border-box;
}
.list {
    margin: 20px 0;
    padding: 10px;
    border: 1px solid #ccc;

}
.list h6{
    padding-bottom: 10px;
    font-size: 16px;
    border-bottom: 1px solid #ccc;
}
.list ul{
    margin: 20px;
    padding: 20px;
    border: 1px solid #CCC;
}
.list li{
    display: flex;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #ccc;
}
.list li span{
    display: inline-block;
    width: 180px;
    text-align: right;
    padding-right: 20px;
    background: #e1e6f0;
}
.list p{
    padding-left: 20px;
}
.btn {
    text-align: center;
    margin-bottom: 20px;
}
.btn span{
    padding: 8px 15px;
    margin-left: 20px;
    color: #fff;
    background: #00aaff;
    border-radius: 8px;
    font-size: 13px;
}
  </style>
  